import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Home } from './pages/Home';
import { Edit } from './pages/Edit';

import fonts from './styles/fonts.module.css';
import styles from './App.module.css';

const App = () => {
  return (
    <div className={[fonts.root, styles.app].join(' ')}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/edit/:id" element={<Edit />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
};

export default App;