import React, { useCallback, useEffect, useState, useRef, createRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import styles from './Edit.module.css';

import { Loader } from '../../components/Loader';
import { Logo } from '../../components/Logo';
import { useApi } from '../../hooks/useAPI';

let tg = window.Telegram.WebApp;

function TextEditor(props) {
  return (
    <>
      <div className={styles.tokens}>
        <span>Tokens: </span>
        <span onClick={props.addToken(props.field)}>@username</span>
        <span onClick={props.addToken(props.field)}>first_name</span>
        <span onClick={props.addToken(props.field)}>last_name</span>
      </div>
      <div className={styles.style}>
        <span>Markdown: </span>
        <span onClick={props.addStyle(props.field)} style={{ fontStyle: 'italic' }}>_Italic_</span>
        <span onClick={props.addStyle(props.field)} style={{ fontWeight: 'bold' }}>*Bold*</span>
        <span onClick={props.addStyle(props.field)}>[inline URL](https://example.com)</span>
        <span onClick={props.addStyle(props.field)}>`inline code`</span>
        <span onClick={props.addStyle(props.field)}>```pre-formatted code block```</span>
      </div>
    </>
  )
}

export function Edit() {
  const navigate = useNavigate();

  const promptRef = useRef(null);
  const generatedRef = useRef(null);

  const { id } = useParams();
  const firstMessageRef = useRef(null);
  const systemPromptRef = useRef(null);
  const descriptionRef = useRef(null);
  const newExampleRef = useRef(null);
  const scenarioRef = useRef(null);
  const bioRef = useRef(null);

  const [generatingField, setGeneratingField] = useState(null);
  const [generated, setGenerated] = useState(null);
  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const { character: { useGet: getCharacter, useUpdate: updateCharacter, useGenerate: generate } } = useApi(apiOptions)

  const { fetch: getCharacterFetch, data: getCharacterData, error: getCharacterError } = getCharacter(id)
  const { fetch: updateCharacterFetch, loading: updateCharacterLoading, data: updateCharacterData, error: updateCharacterError } = updateCharacter(id)
  const { fetch: generateFetch, loading: generateLoading, data: generateData, error: generateError } = generate(id)

  useEffect(() => {
    setUser(tg.initDataUnsafe?.user);
  }, []);

  useEffect(() => {
    if (!user) return
    getCharacterFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (generateData) {
      setGenerated(generateData.result)
    }
  }, [generateData])

  useEffect(() => {
    if (getCharacterError) {
      setErrors([...errors, getCharacterError])
    }

    if (updateCharacterError) {
      setErrors([...errors, updateCharacterError])
    }

    if (generateError) {
      setErrors([...errors, generateError])
    }

    const timeout = setTimeout(() => {
      setErrors([])
    }, 3000)

    return () => clearTimeout(timeout)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCharacterError, updateCharacterError, generateError])

  useEffect(() => {
    if (!getCharacterData) return;
    setCharacter(getCharacterData)
    setLoading(false)
  }, [getCharacterData])

  useEffect(() => {
    if (!updateCharacterData) return;
    setSuccess('Character successfully updated')
    setCharacter(updateCharacterData)

    const timeout = setTimeout(() => {
      setSuccess(null)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [updateCharacterData])

  const handleChange = useCallback((key, target) => (e) => {
    let value = e.target.value;

    switch (key) {
      case 'add_tag':
        if (!value) return;

        setCharacter({
          ...character,
          tags: [...character.tags, value],
        })

        e.target.value = '';

        break;
      case 'remove_tag':
        setCharacter({
          ...character,
          tags: character.tags.filter((tag) => tag !== target),
        })

        break;

      case 'add_example':
        value = newExampleRef.current.value.trim()
        if (!value) return;

        setCharacter({
          ...character,
          examples: [...character.examples, value]
        })

        newExampleRef.current.value = '';

        break;
      case 'remove_example':
        setCharacter({
          ...character,
          examples: character.examples.filter((_example, index) => index !== target),
        })
        break;
      default:
        setCharacter({
          ...character,
          [key]: value,
        })
    }
  }, [character]);

  const textAreaAdjust = useCallback((e) => {
    e.target.style.height = "1px";
    e.target.style.height = (25 + e.target.scrollHeight) + "px";
  }, []);

  const examplesRef = useRef([]);

  useEffect(() => {
    if (character?.examples.length) {
      character.examples.map(() => examplesRef.current.push(createRef()));
    }

    [firstMessageRef, descriptionRef, scenarioRef, systemPromptRef, generatedRef, promptRef, bioRef].forEach((ref) => {
      if (ref.current) {
        ref.current.style.height = "1px";
        ref.current.style.height = (25 + ref.current.scrollHeight) + "px";
      }
    })
  }, [character, generated, isModalOpen])

  useEffect(() => {
    if (character?.examples.length) {
      examplesRef.current = examplesRef.current.slice(0, character.examples.length)
    }
  }, [character])

  useEffect(() => {
    if (examplesRef.current.length) {
      examplesRef.current.forEach((el) => {
        if (el && el.style) {
          el.style.height = "1px";
          el.style.height = (25 + el.scrollHeight) + "px";
        }
      })
    }
  }, [character])

  const handleChangeExamples = useCallback((index) => (e) => {
    let examples = [...character.examples];
    examples[index] = e.target.value;
    examples = examples.filter((example) => example);

    setCharacter({
      ...character,
      examples,
    })
  }, [character])

  const addToken = useCallback((ref) => (key) => (e) => {
    switch (key) {
      case 'add_example':
        ref.current.value = `${ref.current.value} {{${e.target.innerText}}}`.trim();
        break;
      default:
        setCharacter({
          ...character,
          [key]: `${character[key]} {{${e.target.innerText}}}`.trim(),
        })
    }
  }, [character])

  const addStyle = useCallback((ref) => (key) => (e) => {
    switch (key) {
      case 'add_example':
        ref.current.value = `${ref.current.value} ${e.target.innerText}`.trim();
        break;
      default:
        setCharacter({
          ...character,
          [key]: `${character[key]} ${e.target.innerText}`.trim(),
        })
    }
  }, [character])

  const onBack = useCallback(() => {
    navigate('/');
  }, [navigate])

  const handleGenerate = useCallback((field) => async () => {
    generateFetch({
      body: JSON.stringify({ prompt: promptRef.current.value, field }),
    })
  }, [generateFetch])

  const handleApply = useCallback((field) => () => {
    if (field === 'add_example') {
      setCharacter({
        ...character,
        examples: [...character.examples, generated],
      })
    } else {
      setCharacter({
        ...character,
        [field]: generated,
      })
    }

    setIsModalOpen(false)
  }, [character, generated])

  const handleSave = useCallback(() => {
    updateCharacterFetch({
      body: JSON.stringify(character),
    })
  }, [character, updateCharacterFetch])

  useEffect(() => {
    if ("virtualKeyboard" in navigator) {
      navigator.virtualKeyboard.overlaysContent = true;
    }
  }, [])

  const openModal = useCallback((field) => () => {
    if (field === 'add_example') {
      setGenerated('')
      setGeneratingField(field)
    } else {
      setGeneratingField(field)
      setGenerated(character[field])
    }
    setIsModalOpen(true)
  }, [character])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
    setGenerated(null)
  }, [])

  const handleChangeGenerated = useCallback((field) => (e) => {
    setGenerated(e.target.value)
  }, [])

  const onChangePublic = useCallback(() => {
    setCharacter({
      ...character,
      is_public: !character.is_public,
    })
  }, [character])

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.edit}>
      <header>
        <button className={styles.back} onClick={onBack} type="button">← characters</button>
        <Logo />
      </header>

      {errors.length > 0 && (
        <div className={styles.error}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      {success && (
        <div className={styles.success}>
          <div>{success}</div>
        </div>
      )}

      <div className={[styles.modal, isModalOpen ? styles.modalOpen : ''].join(' ')}>
        <div className={styles.generated}>
          <textarea ref={generatedRef} value={generated || ''} onChange={handleChangeGenerated(generatingField)} />
          <div className={styles.buttons}>
            <button onClick={handleApply(generatingField)} className={styles.apply} type='button' disabled={generateLoading}>Apply</button>
            <button onClick={handleCloseModal} className={styles.close} type='button'>Close</button>
          </div>
        </div>

        <textarea className={styles.prompt} ref={promptRef} placeholder='Enter your prompt' maxLength={1024} minLength={1} />
        <button className={styles.generate} onClick={handleGenerate(generatingField)} type='button' disabled={generateLoading}>Generate</button>
      </div>

      <div className={styles.form}>
        <form>
          <div className={styles.botname}>
            <label>Botname</label>
            <span>{`@${character?.botname}`}</span>
          </div>

          <div className={styles.publicContainer}>
            <label htmlFor="characterIsPublic">Public (visible to everyone)</label>
            <input type="checkbox" id="characterIsPublic" value={character?.is_public} checked={character?.is_public} onChange={onChangePublic} />
          </div>

          <div className={styles.nameContainer}>
            <label className={styles.required} htmlFor='characterName'>Name your character</label>
            <input onChange={handleChange('name')} id="characterName" type="text" placeholder="Name" value={character?.name} />
          </div>

          <div className={styles.bioContainer}>
            <button onClick={openModal('bio')} type="button">Generate with AI</button>
            <label htmlFor='characterBio'>Bio (Public visible)</label>
            <textarea onChange={handleChange('bio')} ref={bioRef} id="characterBio" type="text" placeholder="Information about your character for user" value={character?.bio} />
          </div>

          <div className={styles.firstMessageContainer}>
            <label htmlFor='characterFirstMessage'>First message</label>
            <button onClick={openModal('first_message')} type="button">Generate with AI</button>
            <textarea className={styles.firstMessage} ref={firstMessageRef} onKeyUp={textAreaAdjust} onChange={handleChange('first_message')} id="characterFirstMessage" type="text" placeholder="First message" value={character?.first_message} />
            <TextEditor addStyle={addStyle(firstMessageRef)} addToken={addToken(firstMessageRef)} field='first_message' />
          </div>

          <label htmlFor='characterTags'>Tags</label>

          <div className={styles.tags}>
            {
              character?.tags.map((tag) => (
                <div key={tag} className={styles.tag}>
                  {tag}
                  <button onClick={handleChange('remove_tag', tag)} type="button">✖</button>
                </div>
              ))
            }
            <div className={styles.addtag}>
              <input onBlur={handleChange('add_tag')} type="text" placeholder="new tag" />
            </div>
          </div>

          <div className={styles.descriptionContainer}>
            <button onClick={openModal('description')} type="button">Generate with AI</button>
            <label htmlFor='characterDescription'>Description (Privately visible)</label>
            <textarea ref={descriptionRef} onKeyUp={textAreaAdjust} onChange={handleChange('description')} id="characterDescription" type="text" placeholder="Description" value={character?.description} />
          </div>

          <div className={styles.examples}>
            <label>Dialogue examples (Privately visible)</label>
            {
              character?.examples.map((example, index) => (
                <div className={styles.removeExample} key={index}>
                  <textarea onKeyUp={textAreaAdjust} onChange={handleChangeExamples(index)} ref={el => examplesRef.current[index] = el} type="text" value={example} />
                  <button onClick={handleChange('remove_example', index)} type="button" />
                </div>
              ))
            }

            <div className={styles.addExampleContainer}>
              <button onClick={openModal('add_example')} type="button">Generate with AI</button>
              <div className={styles.addExample}>
                <textarea onKeyUp={textAreaAdjust} ref={newExampleRef} type="text" placeholder="new example" />
                <button className={styles.addExample} onClick={handleChange('add_example')} type="button" />
              </div>
              <TextEditor addStyle={addStyle(newExampleRef)} addToken={addToken(newExampleRef)} field='add_example' />
            </div>
          </div>

          <div className={styles.scenarioContainer}>
            <label htmlFor='characterScenario'>Scenario</label>
            <button onClick={openModal('scenario')} type="button">Generate with AI</button>
            <textarea ref={scenarioRef} onKeyUp={textAreaAdjust} onChange={handleChange('scenario')} id="characterScenario" type="text" placeholder="Scenario" value={character?.scenario} />
          </div>

          <div className={styles.systemPromptContainer}>
            <label htmlFor='characterSystemPrompt'>System prompt</label>
            <button onClick={openModal('system_prompt')} type="button">Generate with AI</button>
            <textarea ref={systemPromptRef} onKeyUp={textAreaAdjust} onChange={handleChange('system_prompt')} id="characterSystemPrompt" type="text" placeholder="System prompt" value={character?.system_prompt} />
          </div>
        </form>
      </div>

      <footer className={styles.footer}>
        <button onClick={handleSave} className={styles.save} type="button" disabled={updateCharacterLoading}>Save</button>
      </footer>
    </div>
  )
}