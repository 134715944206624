import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Home.module.css';

import { Loader } from '../../components/Loader';
import { Logo } from '../../components/Logo';
import { useApi } from '../../hooks/useAPI';

let tg = window.Telegram.WebApp;

export function Home() {
  const navigate = useNavigate();

  const [characters, setCharacters] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(6);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);


  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const { character: { useList } } = useApi(apiOptions)

  const { fetch: getCharactersFetch, loading: getCharactersLoading, data: getCharactersData } = useList({ page, pageSize })

  useEffect(() => {
    setUser(tg.initDataUnsafe?.user);
  }, []);

  useEffect(() => {
    if (getCharactersData) {
      setCharacters(getCharactersData.characters)
      setTotal(getCharactersData.total)
      setLoading(false)
    }
  }, [getCharactersData])

  useEffect(() => {
    if (!user) return
    getCharactersFetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!user) return
    getCharactersFetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, user]);

  const onBack = useCallback(() => {
    tg.close()
  }, [])

  if (loading) {
    return <Loader />;
  }

  return (
      <div className={styles.home}>
        <header>
          <button className={styles.back} onClick={onBack} type="button">← telegram</button>
          <Logo />
        </header>
        <div className={styles.characters}>
        {
          characters.map((character) => (
            <div key={character.id} className={styles.character} onClick={() => navigate(`/edit/${character.id}`)}>
              <div className={styles.info}>
                <span className={styles.name}>{character.name}</span>
                <span className={styles.description}>{character.description.slice(0, 128)}...</span>
              </div>
            </div>
          ))
        }

        <div className={styles.pagination}>
          {
            <button className={styles.prev} disabled={page <= 1 || getCharactersLoading} onClick={() => setPage(page - 1)}>prev</button>
          }
          {
            <button className={styles.next} disabled={(page * pageSize) >= total || getCharactersLoading} onClick={() => setPage(page + 1)}>next</button>
          }
        </div>
        </div>
      </div>
    )
  }