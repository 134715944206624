import { useState, useCallback, useMemo } from 'react';

const useApiRequest = (baseUrl, endpoint, requestOptions) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const fetchApi = useCallback(async (fetchOptions) => {
        setLoading(true);
        setError(null);
        setData(null);

        try {
            const headers = {
                'Content-Type': 'application/json',
                ...requestOptions.headers,
            };

            const { query, ..._fetchOptions } = fetchOptions || {}

            const options = {
                headers,
                ...requestOptions,
                ..._fetchOptions,
            };

            let response = await fetch(`${baseUrl}${endpoint}` + (query ? `?${new URLSearchParams(query).toString()}` : ''), options);

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            return setData(await response.json());
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Unknown error occurred');
            }
            return null;
        } finally {
            setLoading(false);
        }
    }, [baseUrl, endpoint, requestOptions])

    return { fetch: fetchApi, loading, error, data };
};

export const useApi = ({ baseUrl, requestOptions }) => {
    const memoizedRequestOptions = useMemo(() => requestOptions, [requestOptions])

    const character = {
        useList: (query) => useApiRequest(baseUrl, `/character?${new URLSearchParams(query).toString()}`, {
            method: 'GET',
            ...memoizedRequestOptions
        }),
        useGet: (id) => useApiRequest(baseUrl, `/character/${id}`, {
            method: 'GET',
            ...memoizedRequestOptions
        }),
        useUpdate: (id) => useApiRequest(baseUrl, `/character/${id}`, {
            method: 'PUT',
            ...memoizedRequestOptions
        }),
        useGenerate: (id) => useApiRequest(baseUrl, `/character/${id}/generate`, {
            method: 'POST',
            ...memoizedRequestOptions
        }),
    };

    return {
        character,
    };
};